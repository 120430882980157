import React from 'react'
import {
  Canvas,
  CanvasProps,
  CustomCode,
  CustomCodeProps,
  Layout,
  LayoutProps,
  Section,
  SectionProps,
  LinkButton,
  LinkButtonProps,
  ImageProps,
  ImpactProps,
  DonationProps,
  FAQ,
  FAQProps,
  ProgressMetricsProps,
  ShareButtonProps,
  SocialLinksProps,
  TransactionDetails,
  TransactionDetailsProps,
  Video,
  VideoProps,
  WysiwygContentProps,
  WysiwygContent,
  BLOCK_TYPES,
  ParentLayoutInfo,
  LayoutBlockInfo,
  CONTAINER_BLOCK_TYPES,
  ActivityFeedProps,
  Member,
} from '@classy/campaign-page-blocks'
import { DonationController } from 'features/Block/donation'
import { Block as PageBlock } from 'features/Block/block.model'
import { LayoutInfo } from './BlockBuilder'
import { ProgressMetricsController } from 'features/Block/progress-metrics'
import { ActivityFeedController } from './activity-feed/ActivityFeed.controller'
import { ImageController } from './image/Image.controller'
import { ImpactController } from './impact/Impact.controller'
import { SocialLinksController } from './social-links/SocialLinks.controller'
import { ShareButtonController } from './share-button/ShareButton.controller'
import { onLogin } from 'services/sso'
import { Environment } from 'utils/environment'

const isContentBlock = (block: PageBlock) =>
  block.type !== CONTAINER_BLOCK_TYPES.CANVAS &&
  block.type !== CONTAINER_BLOCK_TYPES.LAYOUT &&
  block.type !== CONTAINER_BLOCK_TYPES.SECTION

/**
 * Generates a LayoutBlockInfo object for passing to a Layout block.
 */
export function getLayoutBlockInfo(
  block: PageBlock,
  layoutInfo: LayoutInfo,
): LayoutBlockInfo | undefined {
  if (block.type !== CONTAINER_BLOCK_TYPES.LAYOUT || !layoutInfo.layout) {
    return
  }

  return {
    numSections: layoutInfo.layout?.numSections,
    zIndex: layoutInfo.layout.zIndex,
  }
}

/**
 * Generates a ParentLayoutInfo object for passing to a content block.
 */
export function getParentLayoutInfo(
  block: PageBlock,
  layoutInfo: LayoutInfo,
): ParentLayoutInfo | undefined {
  if (!isContentBlock(block) || !layoutInfo.layout || !layoutInfo.section) {
    return
  }

  return {
    numSectionsInParentLayout: layoutInfo.layout?.numSections,
    sectionIndexInLayout: layoutInfo.section?.index,
    sectionHasOneBlock: layoutInfo.section?.hasOneBlock,
  }
}

/**
 * Generate an id that identifies a section’s placement and type of layout.
 */
export const generateSectionId = (layoutInfo?: LayoutInfo) => {
  if (!layoutInfo || !layoutInfo.layout || !layoutInfo.section) {
    return 'cp-section-no-layout-info'
  }

  /**
   * start with the top most layout as verticalPosition = 1
   * and increment as you move down the page
   */
  const verticalPosition = layoutInfo.layout.index + 1

  /**
   * number of columns in the layout.
   */
  const layoutType = `${layoutInfo.layout.numSections}col`

  /**
   * start with the left most section as horizontalPosition = 1
   * and increment as you move horizontally across the page.
   */
  const horizontalPosition = layoutInfo.section.index + 1

  return `cp-section-${verticalPosition}-${layoutType}-${horizontalPosition}`
}

/**
 * External data props available to every page block. Whether a block actually uses it is dictated
 * by the individual case statements in the <Block/> component below.
 *
 * ! This is not a dumpster for props. Exhaust the following first:
 * ! - "getServerSideProps()"
 * !     - Intended for prop values that are static per the campaign (i.e. campaignId).
 * !     - Preferred because the prop value is retrieved/set server-side.
 * !     - Search for "getBlocksMap" for examples.
 * !     - Note, in some cases, prop values may be duplicated across blocks. That is okay.
 * ! - "features/Block/<block-name>/BlockName.controller.tsx"
 * !     - Intended for prop values that are NOT static per campaign (i.e. campaign progress),
 * !       or MUST be fetched client-side (i.e. activity feed items) to avoid being cached.
 */
export interface GlobalBlockProps {
  /**
   * Currently, we fetch/verify a member session using the `useSsoMember` hook, which triggers a
   * number of fetch calls. Adding `useSssoMember` to block controllers would be heavy. Therefore,
   * we allow passing it to <BlockBuilder/> as a global prop.
   */
  member?: Member
}

export interface BlockProps extends GlobalBlockProps {
  blockData: PageBlock
  children?: React.ReactNode
  layoutInfo: LayoutInfo
  /**
   * ! Only for use by block controllers.
   * ! For all that is pure and magical, DO NOT pass it into any page block.
   */
  __ENV?: Environment
}

/**
 * Block has two functions.
 * 1) It selects the correct Block and returns it (with children if appropriate).
 * 2) It injects Block data via props.
 *
 * ----
 *
 * What's up with the `layoutInfo` prop?
 *
 * ! Naming is hard, the `layoutInfo` prop does not refer to the Layout page block.
 *
 * Page blocks are unable to make styling decisions based on positioning and children. This is
 * due to Rich Mahogany inserting its own components into the block tree, which changes the
 * children passed into a page block. The workaround is to pass special props to some page blocks
 * with the information necessary to assist the CSS.
 *
 * At the moment, there are two props that get passed to some page blocks:
 * - layoutBlockInfo: Passed to Layout blocks. See the LayoutBlockInfo interface.
 * - parentLayouInfo: Passed to content blocks. See the ParentLayoutInfo interface.
 *
 * Example:
 * ```
 * Canvas
 *   Layout     -> <Layout layoutBlockInfo={{ numSections: 2 }} />
 *     Section
 *     Section
 *   Layout     -> <Layout layoutBlockInfo={{ numSections: 1 }} />
 *     Section
 *       Image  -> <Image parentLayoutInfo={{ numSectionsInParentLayout: 1, ... }} />
 * ```
 *
 * Even though Whammy stays true to the page block tree, it needs to pass the same props to
 * maintain consistency with Rich Mahogany.
 *
 * Additionally, `layoutInfo` is used to add `data-id` attributes to some page blocks for tracking
 * by analytics engines.
 *
 * See BlockBuilder.tsx for how `layoutInfo` is built.
 *
 * ! Heads up, this is the most important thing you will read in this codebase:
 * ! Before adjusting layoutBlockInfo, parentLayoutInfo, or adding a similar prop, test your
 * ! assumptions in Rich Mahogany first! It's not as straightforward there due to performance
 * ! concerns. Get it working there, then adjust Whammy.
 */
export const Block = ({ blockData, children, layoutInfo, member, __ENV }: BlockProps) => {
  let parentLayoutInfo
  if (isContentBlock(blockData)) {
    parentLayoutInfo = getParentLayoutInfo(blockData, layoutInfo)
  }

  switch (blockData.type) {
    // CONTAINER BLOCKS

    case BLOCK_TYPES.CANVAS: {
      return <Canvas {...(blockData.props as CanvasProps)}>{children}</Canvas>
    }

    case BLOCK_TYPES.LAYOUT: {
      return (
        <Layout
          {...(blockData.props as LayoutProps)}
          layoutBlockInfo={getLayoutBlockInfo(blockData, layoutInfo)}
        >
          {children}
        </Layout>
      )
    }

    case BLOCK_TYPES.SECTION: {
      return (
        <Section {...(blockData.props as SectionProps)} data-id={generateSectionId(layoutInfo)}>
          {children}
        </Section>
      )
    }

    // WYSIWYG BLOCKS

    case BLOCK_TYPES.HEADING: {
      // Keep default contentHTML in sync with DEFAULT_HEADING_BLOCK_DATA in Rich Mahogany
      return (
        <WysiwygContent
          contentHTML={`<h2><span style="font-family:var(--theme-font__font-family--heading); font-size:var(--theme-font__heading-md--font-size); line-height:var(--theme-font__heading-md--line-height);">This is a <span style="font-family:var(--theme-font__font-family--heading); font-size:var(--theme-font__heading-md--font-size); line-height:var(--theme-font__heading-md--line-height);color:var(--theme-color__brand-primary);"><strong>Heading</strong></span><span style="font-family:var(--theme-font__font-family--heading); font-size:var(--theme-font__heading-md--font-size); line-height:var(--theme-font__heading-md--line-height);">. You can edit my contents directly on the page.</span>\n</h2>`}
          {...(blockData.props as WysiwygContentProps)}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    case BLOCK_TYPES.PARAGRAPH: {
      // Keep default contentHTML in sync with DEFAULT_PARAGRAPH_BLOCK_DATA in Rich Mahogany
      return (
        <WysiwygContent
          contentHTML={`<p><span style="font-family:var(--theme-font__font-family--paragraph); font-size:var(--theme-font__paragraph-md--font-size); line-height:var(--theme-font__paragraph-md--line-height);">This is a <span style="font-family:var(--theme-font__font-family--paragraph); font-size:var(--theme-font__paragraph-md--font-size); line-height:var(--theme-font__paragraph-md--line-height);color:var(--theme-color__brand-primary);"><strong>Paragraph</strong></span><span style="font-family:var(--theme-font__font-family--paragraph); font-size:var(--theme-font__paragraph-md--font-size); line-height:var(--theme-font__paragraph-md--line-height);">. You can edit my contents directly on the page.</span></p>`}
          {...(blockData.props as WysiwygContentProps)}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    case BLOCK_TYPES.TITLE: {
      // Keep default contentHTML in sync with DEFAULT_TITLE_BLOCK_DATA in Rich Mahogany
      return (
        <WysiwygContent
          contentHTML={`<h1><span style="font-family:var(--theme-font__font-family--heading); font-size:var(--theme-font__heading-xl--font-size); line-height:var(--theme-font__heading-xl--line-height);">This is a <span style="font-family:var(--theme-font__font-family--heading); font-size:var(--theme-font__heading-xl--font-size); line-height:var(--theme-font__heading-xl--line-height);color:var(--theme-color__brand-primary);"><strong>Title</strong></span><span style="font-family:var(--theme-font__font-family--heading); font-size:var(--theme-font__heading-xl--font-size); line-height:var(--theme-font__heading-xl--line-height);">. You can edit my contents directly on the page.</span></h1>`}
          {...(blockData.props as WysiwygContentProps)}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    // CONTENT BLOCKS

    case BLOCK_TYPES.ACTIVITY_FEED: {
      const activityFeedProps = blockData.props as ActivityFeedProps

      return (
        <ActivityFeedController
          {...activityFeedProps}
          onLogin={() => onLogin(__ENV as Environment, activityFeedProps.orgId)}
          member={member}
        />
      )
    }

    case BLOCK_TYPES.CUSTOM_CODE: {
      return (
        <CustomCode {...(blockData.props as CustomCodeProps)} parentLayoutInfo={parentLayoutInfo} />
      )
    }

    case BLOCK_TYPES.DONATION: {
      return (
        <DonationController
          {...(blockData.props as DonationProps)}
          parentLayoutInfo={parentLayoutInfo}
          /**
           * This generates the same ID as the parent section.
           * This ID is used in the Heap event we send once the DonationBlock has initialized
           */
          sectionId={generateSectionId(layoutInfo)}
        />
      )
    }

    case BLOCK_TYPES.FAQ: {
      return <FAQ {...(blockData.props as FAQProps)} parentLayoutInfo={parentLayoutInfo} />
    }

    case BLOCK_TYPES.IMAGE: {
      return (
        <ImageController {...(blockData.props as ImageProps)} parentLayoutInfo={parentLayoutInfo} />
      )
    }

    case BLOCK_TYPES.IMPACT: {
      return (
        <ImpactController
          {...(blockData.props as ImpactProps)}
          blockId={blockData.id}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    case BLOCK_TYPES.LINK_BUTTON: {
      return (
        <LinkButton {...(blockData.props as LinkButtonProps)} parentLayoutInfo={parentLayoutInfo} />
      )
    }

    case BLOCK_TYPES.PROGRESS_METRICS: {
      return (
        <ProgressMetricsController
          {...(blockData.props as ProgressMetricsProps)}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    case BLOCK_TYPES.SHARE_BUTTON: {
      return (
        <ShareButtonController
          {...(blockData.props as ShareButtonProps)}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    case BLOCK_TYPES.SOCIAL_LINKS: {
      return (
        <SocialLinksController
          {...(blockData.props as SocialLinksProps)}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    case BLOCK_TYPES.TRANSACTION_DETAILS: {
      return (
        <TransactionDetails
          {...(blockData.props as TransactionDetailsProps)}
          parentLayoutInfo={parentLayoutInfo}
        />
      )
    }

    case BLOCK_TYPES.VIDEO: {
      return <Video {...(blockData.props as VideoProps)} parentLayoutInfo={parentLayoutInfo} />
    }

    default:
      return <></>
  }
}
