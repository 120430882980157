import React from 'react'
import type { NextPage, GetServerSideProps, GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { ParsedUrlQuery } from 'querystring'
import {
  MIN_DONATION_AMOUNT,
  type ActivityFeedProps,
  type BlockIdToProgramDesignations,
  type DonationProps,
  type ImpactProps,
  type LayoutProps,
  type ProgramDesignation,
  type ProgressMetricsProps,
  type ShareButtonProps,
  type SocialLinksProps,
} from '@classy/campaign-page-blocks'
import { CampaignPage, CampaignPageProps } from 'components/CampaignPage'
import { OpenGraphTags } from 'components/OpenGraphTags'
import { PAGE_TYPES, PageConfig } from 'models/pages'
import { getMockData, getMockPageConfig } from 'services/mock-data'
import {
  findCampaignPage,
  denormalizeLayoutWithBlocks,
  getCampaignData,
  getCampaignPagesData,
  getLegacyCampaignData,
  isCampaignInactive,
} from 'services/campaign'
import {
  addSocialLinksProps,
  buildSocialShareLinks,
  checkIsInstagramSharingEnabled,
} from 'features/Block/social-links'
import { getOrganizationData, getOrganizationTags } from 'services/organization'
import { domainMaskRedirect, getDomainFromRequest, getPrimaryDomain } from 'services/domain'
import { getOrganizationChannels } from 'services/analytics'
import { getCheckoutQueryParamsToForward } from 'features/Block/donation/getCheckoutQueryParamsToForward'
import { getPageMetaDescription, getPageTitle } from 'utils/head'
import { isDomainValid } from 'utils/isDomainValid'
import { resolveFaviconUrl } from 'utils/resolveFaviconUrl'
import { __ENV } from 'utils/environment'
import { addImpactBlockProps, getProgramDesignationsFromImpactBlocks } from 'features/Block/impact'
import { getBlocksMap } from 'features/Block/utils/getBlocksMap'
import { addDonationBlockProps } from 'features/Block/donation/donation'
import { Block } from 'features/Block/block.model'
import { logger } from 'utils/logger'
import { getDesignationData, toProgramDesignation } from 'services/designations'
import { DesignationDto } from 'models/designations'
import { useIntelligentAskAmount } from 'hooks/useIntelligentAskAmount'
import { getFooter, addFooterBlockProps } from 'features/Block/footer/footer'
import { getPageHeader, addPageHeaderBlockProps } from 'features/Block/page-header/page-header'
import { addLayoutBlockProps } from 'features/Block/layout/layout'
import { addActivityFeedProps } from 'features/Block/activity-feed/activity-feed'
import { addProgressMetricsProps } from 'features/Block/progress-metrics/progress-metrics'
import { getCurrencyOptions } from 'services/currency'
import { addShareButtonProps } from 'features/Block/share-button/share-button'
import { getOrganizationMetaFrapiIntegration } from 'services/organization/integrations'

interface DonationPageProps extends CampaignPageProps {}

interface DonationPageQuery extends ParsedUrlQuery {
  mock?: string
  delay?: string
  campaignId: string
  pageId: string
}

const DonationPage: NextPage<DonationPageProps> = ({
  pageData,
  sharedBlocks,
  theme,
  pageConfig,
  isAnalyticsLoaded,
  __ENV,
}) => {
  const currentUrl = `https://${pageConfig.currentDomain}${pageConfig.pagePathname}`

  useIntelligentAskAmount(pageConfig.campaignId, pageConfig.intelligentAskAmount)

  return (
    <>
      <Head>
        <link rel="icon" href={resolveFaviconUrl(pageConfig.orgDomainMaskFavicon)} />
        <title>{pageConfig.pageTitle}</title>
        <meta
          name="description"
          content={getPageMetaDescription(PAGE_TYPES.DONATION, pageConfig)}
        />
      </Head>
      <OpenGraphTags
        facebookAppId={__ENV?.facebookAppId}
        facebookText={pageConfig.openGraphTagFacebookText || ''}
        facebookImageUrl={pageConfig.openGraphTagFacebookImageUrl || ''}
        orgName={pageConfig.orgName}
        currentUrl={currentUrl}
      />
      <CampaignPage
        __ENV={__ENV}
        theme={theme}
        pageData={pageData}
        sharedBlocks={sharedBlocks}
        pageConfig={pageConfig}
        isAnalyticsLoaded={isAnalyticsLoaded}
      />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const { query, req, res, resolvedUrl } = context
  const { campaignId, delay = '0', mock, skipDomainMaskRedirect } = query as DonationPageQuery

  logger('trace', 'Start compiling donation page', {
    context: { campaignId, query, resolvedUrl },
  })

  try {
    if (mock) {
      const { theme, sharedBlocks, pageData } = await getMockData(
        Number(delay),
        campaignId,
        PAGE_TYPES.DONATION,
      )

      const { pageConfig } = await getMockPageConfig(campaignId, PAGE_TYPES.DONATION)

      return {
        props: {
          __ENV,
          theme,
          sharedBlocks,
          pageData,
          pageConfig,
        },
      }
    }

    /**
     * - Fetch the raw campaign data
     * - Fetch the donation page
     */
    const published = true
    const [campaign, campaignPages] = await Promise.all([
      getCampaignData({ campaignId, req, published }),
      getCampaignPagesData({ campaignId, req, published }),
    ])

    const [organization, domainMask, dataUseRestrictionTag, disableMulticurrencyTag] =
      await Promise.all([
        getOrganizationData({
          organizationId: campaign.organization_id,
          req,
        }),
        getPrimaryDomain({
          organizationId: campaign.organization_id,
          req,
        }),
        getOrganizationTags({
          organizationId: campaign.organization_id,
          filter: 'name=data-use-restriction',
          req,
        }),
        getOrganizationTags({
          organizationId: campaign.organization_id,
          filter: 'name=disable-multi-currency',
          req,
        }),
      ])

    const domain = getDomainFromRequest(req)
    const hostUrl = `https://${domain}`

    if (!isDomainValid(domain, domainMask)) {
      logger('error', new Error('Invalid domain or domain mask'), {
        context: { campaignId, query, domain, domainMask },
      })

      return {
        notFound: true,
      }
    }

    // When we implement Cloudflare caching on our pages, we will likely need to put in special rule
    // to specifically cache redirects (i.e. 308s) as Cloudflare does not cache certain redirects by
    // default: https://developers.cloudflare.com/cache/how-to/configure-cache-status-code
    if (!skipDomainMaskRedirect) {
      const redirect = domainMaskRedirect({
        currentDomain: domain,
        domainMask,
        url: resolvedUrl,
      })
      if (redirect) {
        return redirect
      }
    }

    if (isCampaignInactive(campaign)) {
      logger('warn', new Error('Campaign is inactive'), {
        context: {
          campaignId,
          externalUrl: campaign?.campaign_data?.external_url,
          organizationUrl: organization?.url,
        },
      })

      return {
        notFound: !campaign?.campaign_data?.external_url && !organization?.url,
        redirect: {
          permanent: false,
          destination: campaign?.campaign_data?.external_url || organization?.url,
        },
      }
    }

    const { theme, sharedBlocks } = campaignPages

    const donationPage = findCampaignPage(campaignPages, PAGE_TYPES.DONATION)
    if (!donationPage) {
      const error = new Error('Unable to find donation page in campaignPages data')
      logger('error', error, { context: { campaignId, campaignPages } })
      throw error
    }

    let donationPageData: Block
    try {
      donationPageData = denormalizeLayoutWithBlocks(donationPage)
    } catch (e) {
      const error = new Error('Unable to denormalize donation page data', { cause: e })
      logger('error', error, {
        context: { campaignId, blocks: donationPage.blocks, layout: donationPage.layout },
      })
      throw error
    }

    const getCheckoutBaseUrl = () => {
      if (domainMask) {
        return hostUrl
      }

      return `https://${process.env.CLASSY_STUDIO_DOMAIN}`
    }

    /**
     * * IAA Setup
     */

    // If an org has a data use restriction tag, make sure IAA is set to false
    const dataUseRestriction = dataUseRestrictionTag?.data?.length === 1

    /**
     * If one of use_intelligent_ask_recurring or use_intelligent_ask_onetime is enabled,
     * we will want to fetch IAA base amounts
     *
     * If one is enabled and one disabled, the response from IAA would look like:
     * { suggested_donation_amount: number, suggested_recurring_amount: null}
     */
    const intelligentAskAmount = {
      onetimeEnabled:
        !dataUseRestriction && (campaign?.campaign_data?.use_intelligent_ask_onetime || false),
      recurringEnabled:
        !dataUseRestriction && (campaign?.campaign_data?.use_intelligent_ask_recurring || false),
    }

    const [currencies, organizationChannels, organizationMetaFrapiIntegration] = await Promise.all([
      getCurrencyOptions(campaign.campaign_data.raw_currency_code),
      getOrganizationChannels({
        organizationId: campaign.organization_id,
        req,
      }),
      getOrganizationMetaFrapiIntegration({
        organizationId: campaign.organization_id,
        req,
      }),
    ])

    /**
     * Whammy-specific runtime data (not injected into block data). All attributes MUST
     * have a value as any props returned by getServerSideProps will be serialized
     * and undefined values will throw an error.
     */
    const pageConfig: PageConfig = {
      analyticsServiceSettings: campaign?.modules?.analytics_service_settings ?? [],
      campaignId,
      campaignName: campaign.campaign_data.name,
      campaignCreatedAt: campaign.created_at,
      campaignRawCurrencyCode: campaign.campaign_data.raw_currency_code,
      campaignRawGoal: campaign.campaign_data.raw_goal,
      currencies,
      defaultDesignationId: campaign.campaign_data.designation_id,
      checkoutBaseUrl: getCheckoutBaseUrl(),
      currentDomain: domain,
      forwardCheckoutQueryParams: getCheckoutQueryParamsToForward(query),
      isCartEnabled: campaign?.checkout?.cart?.is_enabled ?? false,
      isPassportDisabled: disableMulticurrencyTag?.data?.length === 1,
      openGraphTagFacebookText: campaign?.modules.sharing?.facebook_text || '',
      openGraphTagFacebookImageUrl: campaign?.modules.sharing?.facebook_image_url || '',
      organizationChannels: organizationChannels?.data || [],
      orgDomainMaskFavicon: domainMask?.favicon ?? '',
      orgId: campaign.organization_id,
      orgName: organization.name,
      pagePathname: req.url ?? '',
      pageTitle: getPageTitle(PAGE_TYPES.DONATION, campaign.campaign_data.name),
      intelligentAskAmount: intelligentAskAmount,
    }

    // * Adjust shared blocks

    const pageHeaderBlock = getPageHeader(sharedBlocks)
    if (pageHeaderBlock) {
      addPageHeaderBlockProps(pageHeaderBlock.props, organization)
    }

    const footerBlock = getFooter(sharedBlocks)
    if (footerBlock) {
      addFooterBlockProps(footerBlock.props, organization, campaign)
    }

    // * Adjust non-shared blocks

    const blocksMap = getBlocksMap(donationPageData)

    if (blocksMap['activity-feed']) {
      // ! Assumes a campaign page will only have a single activity feed block
      const activityFeed = blocksMap['activity-feed'][0]
      addActivityFeedProps(
        // Force narrowing type (AnyBlockProps -> ActivityFeedProps)
        activityFeed.props as ActivityFeedProps,
        campaignId,
        campaign.organization_id,
        campaign?.modules?.donation_matching_plan,
      )
    }

    // TODO added per CL-39568, clean up with CL-40328
    blocksMap.layout?.forEach((layoutBlock) => {
      addLayoutBlockProps(layoutBlock.props as LayoutProps, campaignPages.schema_version)
    })

    if (blocksMap['progress-metrics']) {
      blocksMap['progress-metrics'].forEach((progressMetricsBlock) => {
        addProgressMetricsProps(
          progressMetricsBlock.props as ProgressMetricsProps,
          campaign.campaign_data.raw_currency_code,
        )
      })
    }

    if (blocksMap['social-links'] || blocksMap['share-button']) {
      const socialShareLinks = buildSocialShareLinks(campaign, domainMask?.value)
      const isInstagramSharingEnabled = checkIsInstagramSharingEnabled(
        organizationMetaFrapiIntegration,
        campaign.modules.campaign_channel || [],
      )

      blocksMap['social-links']?.forEach((socialLinksBlock) => {
        addSocialLinksProps(
          socialLinksBlock.props as SocialLinksProps,
          socialShareLinks,
          isInstagramSharingEnabled,
        )
      })

      blocksMap['share-button']?.forEach((shareButtonBlock) => {
        addShareButtonProps(
          shareButtonBlock.props as ShareButtonProps,
          socialShareLinks,
          isInstagramSharingEnabled,
        )
      })
    }

    let defaultProgramDesignationId: number | null = null
    let defaultProgramDesignation: ProgramDesignation = {} as ProgramDesignation
    let programDesignations: BlockIdToProgramDesignations = {}
    try {
      /**
       * Under Settings > Program Designations, the value for sort_designation_by is saved at the
       * legacy APIv2 /campaigns/{id} end point.
       *
       * https://docs.classy-test.org/apiv2/#tag/Campaign/operation/fetchCampaign
       *
       * Then fetch the campaign's sorted program designation group by sort_designation_by.
       * Assumes designation_id is the campaign's default program designation.
       */
      const { designation_id, sort_designation_by } = await getLegacyCampaignData({
        campaignId,
        req,
      })

      defaultProgramDesignationId = designation_id

      const designationData = await getDesignationData({
        designationId: designation_id,
        req,
      })

      const designationDataJson: DesignationDto = await designationData.json()
      defaultProgramDesignation = toProgramDesignation(designationDataJson)

      if (blocksMap.impact) {
        programDesignations = await getProgramDesignationsFromImpactBlocks(
          blocksMap.impact,
          sort_designation_by,
          defaultProgramDesignation,
          req,
          campaignId,
        )
      }
    } catch (e) {
      const error = new Error('Unable to apply program designations to blocks', { cause: e })
      logger('error', error, {
        context: { defaultProgramDesignationId, defaultProgramDesignation },
      })

      throw error
    }

    blocksMap.impact?.forEach((impactBlock) => {
      addImpactBlockProps(
        // Force narrowing type (AnyBlockProps -> ImpactProps)
        impactBlock.props as ImpactProps,
        campaignId,
        pageConfig.checkoutBaseUrl || '',
        pageConfig.forwardCheckoutQueryParams || {},
        programDesignations,
        campaign.checkout?.cart?.is_enabled || false,
        campaign.campaign_data.minimum_donation_amount || MIN_DONATION_AMOUNT,
        campaign.organization_id,
        campaign.campaign_data.raw_currency_code || 'USD',
      )
    })

    blocksMap.donation?.forEach((donationBlock) => {
      addDonationBlockProps(
        // Force narrowing type (AnyBlockProps -> DonationProps)
        donationBlock.props as DonationProps,
        query,
        campaignId,
        campaign.organization_id,
        campaign.campaign_data.dcf_enabled,
        intelligentAskAmount,
        defaultProgramDesignation,
        pageConfig.forwardCheckoutQueryParams || {},
        campaign?.campaign_data?.minimum_donation_amount || MIN_DONATION_AMOUNT,
        campaign?.checkout?.cart?.is_enabled ?? false,
        pageConfig.checkoutBaseUrl || '',
        campaign.campaign_data.raw_currency_code,
      )
    })

    // * Set CloudFlare cache-tag
    res.setHeader(
      'Cache-Tag',
      `campaign-${campaignId}-${PAGE_TYPES.DONATION},campaign-${campaignId},organization-${campaign.organization_id}`,
    )

    logger('trace', 'Successfully compiled donation page', {
      context: { campaignId },
    })

    return {
      props: {
        __ENV,
        theme,
        sharedBlocks,
        pageData: donationPageData,
        pageConfig,
      },
    }
  } catch (e) {
    logger('error', new Error('Unable to compile donation page', { cause: e }), {
      context: { campaignId, query, resolvedUrl },
    })

    return {
      notFound: true,
    }
  }
}

export default DonationPage
