import { CampaignTheme, CheckoutQueryParams } from '@classy/campaign-page-blocks'
import { Block, SharedBlock } from '../features/Block/block.model'
import { AnalyticsServiceSettingsDto } from './studio'
import type { OrganizationChannel } from 'services/analytics'

/**
 * Interfaces/types/constants for page-related information.
 */

export const PAGE_TYPES = {
  LANDING: 'landing',
  DONATION: 'donation',
  THANK_YOU: 'thank-you',
  TEAM: 'team',
  FUNDRAISER: 'fundraiser',
} as const

export type PageType = (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES]

/**
 * The shape of a campaign page with its "layout" and "blocks" objects combined.
 */
export interface DenormalizedPageData {
  campaign_id: string
  theme: CampaignTheme
  sharedBlocks: Record<string, SharedBlock>
  pageData: Block
}

/**
 * Whammy data necessary to render the page.
 *
 * Generated in getServerSideProps().
 * Used by DonationPage, ThankYouPage, etc.
 */
export interface PageConfig {
  analyticsServiceSettings?: AnalyticsServiceSettingsDto[]
  campaignId: string
  campaignName: string
  campaignCreatedAt: string
  campaignRawCurrencyCode: string
  campaignRawGoal: number
  organizationChannels?: OrganizationChannel[]
  checkoutBaseUrl?: string
  currencies?: { value: string; label: string }[]
  currentDomain?: string
  defaultDesignationId: number
  forwardCheckoutQueryParams?: CheckoutQueryParams
  intelligentAskAmount?: {
    onetimeEnabled: boolean
    recurringEnabled: boolean
  }
  isCartEnabled?: boolean
  isPassportDisabled?: boolean
  openGraphTagFacebookText?: string
  openGraphTagFacebookImageUrl?: string
  orgDomainMaskFavicon?: string
  orgId: string
  orgName: string
  pagePathname?: string
  pageTitle: string
}
