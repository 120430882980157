import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { TYPOGRAPHY_PRESETS } from '@classy/campaign-page-blocks'
import { lineClamp } from 'utils/styles'

export interface CardProps {
  cartItemCardHeight: number
}

export const Card = styled.div<CardProps>`
  padding: 12px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  position: relative;
  width: 100%;
  opacity: 1;

  :last-child {
    margin-bottom: 20px;
  }

  // fade out the deleted cart item
  &.fadeout {
    opacity: 0;
    transition:
      opacity 0.5s,
      transform 0.5s;

    // slide up any divs after the deleted cart item
    ~ div {
      ${(props) => css`
        /* the value we pass to translateY is the gap between cart items + the height of the cart item card being deleted. We multiply by -1 to make the value negative so that the divs slide up */
        transform: translateY(
          calc((var(--cart__cart-item--gap) + ${props.cartItemCardHeight}px) * -1)
        );
        transition: transform 0.5s;
      `}
    }
  }
`

export const InfoContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
`

export const Title = styled.span`
  ${TYPOGRAPHY_PRESETS.classyParagraphMdBold}
  margin-inline-end: 48px;
  ${lineClamp(2)}
`

export const Description = styled.div`
  ${TYPOGRAPHY_PRESETS.classyParagraphSm}
  ${lineClamp(3)}
  margin-inline-end: 48px;
  margin-block-end: 12px;
`

export const FrequencyBadge = styled.span`
  background-color: rgb(242, 241, 239);
  border-radius: 4px;
  padding: 2px 4px;
  margin-inline-end: 50px;
  display: flex;
  align-items: center;
`

export const Frequency = styled.span`
  ${TYPOGRAPHY_PRESETS.classyLabel}
`

export const Amount = styled.span`
  ${TYPOGRAPHY_PRESETS.classyParagraphMdBold}
`

export const ButtonContainer = styled.button`
  background-color: transparent;
  border: none;

  position: absolute;
  top: 6px;
  right: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 36px;
  min-width: 36px;

  &:focus-visible {
    outline: 2px dotted var(--classy-color__border--focus);
    border-radius: 4px;
  }

  // prevents hover state persisting after button is clicked/touched in mobile
  @media (hover: hover) {
    &:hover {
      border-radius: 4px;
      background-color: var(--classy-color__status--error-lightest);
      cursor: pointer;

      & > svg {
        fill: var(--classy-color__status--error-base);

        & > path {
          fill: var(--classy-color__status--error-base);
        }
      }
    }
  }
`
