import { getAbortSignal, handleFetchWithAbortException } from 'utils/abortSignal'

export interface IntelligentAskAmountEventPayload {
  isLoading: boolean
  suggested_donation_amount: string | null
  suggested_recurring_amount: string | null
  grid: {
    suggested_donation_amount: number[] | null
    suggested_recurring_amount: number[] | null
  }
}

export const getIntelligentAskAmount = async (campaignId?: string) => {
  let iaaBaseAmounts = {
    suggested_donation_amount: null,
    suggested_recurring_amount: null,
    grid: {
      suggested_donation_amount: null,
      suggested_recurring_amount: null,
    },
  }

  const screenHeight = window?.screen?.height || 0
  const screenWidth = window?.screen?.width || 0

  try {
    const response = await fetch('/api/intelligentAskAmount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // Abort the fetch if it takes longer than 1 second
      signal: getAbortSignal(1000),
      body: JSON.stringify({
        campaignId,
        screenHeight,
        screenWidth,
      }),
    })

    const json = await response.json()

    iaaBaseAmounts = {
      suggested_donation_amount: json?.suggested_donation_amount,
      suggested_recurring_amount: json?.suggested_recurring_amount,
      grid: { ...json?.grid },
    }
  } catch (e) {
    handleFetchWithAbortException(
      e as Error,
      `Unable to fetch Intelligent Ask Amount for ${campaignId}`,
    )
  } finally {
    /**
     * iaa will return null for a suggested amount
     * if the suggested amount is not enabled by the campaign
     *
     * { suggested_donation_amount: number, suggested_recurring_amount: null}
     */
    return iaaBaseAmounts
  }
}
