import type { AnalyticsPlugin } from 'analytics'
import type { SuperProperties, PluginData } from '../analytics.model'
import { flattenObjectForTealium } from '../dataLayerConverters'

type TealiumSettings = SuperProperties & {
  account: string
  profile: string
}

type TealiumPluginData = PluginData & {
  config: TealiumSettings
}

/**
 * * Custom Tealium analytics plugin
 */
export const tealium = (settings: TealiumSettings): AnalyticsPlugin => ({
  name: 'tealium',
  config: flattenObjectForTealium(settings),

  initialize({ config }: TealiumPluginData): void {
    const { account, profile, ...superProperties } = config

    // * Create Tealium Settings object
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
    // * Disable the automatic tracking call on initial page load.
    window.utag_cfg_ovrd.noview = true

    // * Init Tealium Script
    const tealiumScript = document.createElement('script')
    tealiumScript.id = 'classy-tealium-script'
    tealiumScript.type = 'text/javascript'
    tealiumScript.async = true
    tealiumScript.src = `https://tags.tiqcdn.com/utag/${account}/${profile}/${superProperties.environment}/utag.js`
    // * Inject Tealium Script into the DOM
    document.body.prepend(tealiumScript)

    // * Init Universal Data Object Script
    const utagScript = document.createElement('script')
    tealiumScript.id = 'classy-tealium-utag-object'
    utagScript.type = 'text/javascript'
    utagScript.async = true
    utagScript.text = `var utag_data = ${JSON.stringify(superProperties)}`
    // * Inject Universal Data Object Script into the DOM
    document.body.prepend(utagScript)

    // * Init A/B Test Script
    const abTestScript = document.createElement('script')
    tealiumScript.id = 'classy-tealium-sync-script'
    abTestScript.type = 'text/javascript'
    abTestScript.async = true
    abTestScript.src = `https://tags.tiqcdn.com/utag/${account}/${profile}/${superProperties.environment}/utag.sync.js`
    // * Inject A/B Test Script into the DOM
    document.body.prepend(abTestScript)

    /**
     * ! Heap Fix: Overriding setAttribute to block Tealium from loading a second Heap script
     * 1. Access the setAttribute for all script elements via the prototype chain
     * 2. Override setAttribute method with custom logic that checks for Tealium setting Heap src
     * 3. Shim the container's heap.push method to avoid a console error
     * 4. Empty return statement to prevent settting the src
     * 5. Finally we add a call to provide the default behavior for other attributes
     *
     * ! Overriding setAttribute like this can only be done once. If another analytics plugin also
     * ! needs to override it, then the last plugin to do so will be the one that is used.
     */
    const originalSetAttribute = HTMLScriptElement.prototype.setAttribute

    HTMLScriptElement.prototype.setAttribute = function (name, value) {
      if (
        // skip classy scripts where id starts with 'classy'
        this.id.substring(0, 6) !== 'classy' &&
        name === 'src' &&
        value.substring(0, 23) === '//cdn.heapanalytics.com'
      ) {
        this.innerHTML = 'window.heap.push = () => {};'
        return
      }

      originalSetAttribute.call(this, name, value)
    }
  },
  page({ payload }: TealiumPluginData): void {
    const flatProperties = flattenObjectForTealium(payload.properties)
    window.utag.view(flatProperties)
  },
  track({ payload }: TealiumPluginData): void {
    const flatProperties = flattenObjectForTealium({
      tealium_event: payload.event,
      ...payload.properties,
    })
    window.utag.link(flatProperties)
  },
  loaded() {
    return !!window.utag
  },
})
