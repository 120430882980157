import { getAnalyticsInstance } from '../analytics'
import { PageConfig } from 'models/pages'
import { Member } from 'models/member'

export const trackCustomPageView = async (pageConfig: PageConfig, member: Member | null) => {
  const analytics = getAnalyticsInstance()

  const payload = {
    campaign: {
      created_at: pageConfig.campaignCreatedAt,
      currency_code: pageConfig.campaignRawCurrencyCode,
      default_designation_id: pageConfig.defaultDesignationId,
      features: pageConfig.isCartEnabled ? ['cart'] : [],
      goal: pageConfig.campaignRawGoal,
      id: Number(pageConfig.campaignId),
      is_embedded: false,
      name: pageConfig.campaignName,
      type: 'studio',
    },
    organization: {
      id: pageConfig.orgId,
      name: pageConfig.orgName,
    },
    page: {
      name: pageConfig.pageTitle,
      path: pageConfig.pagePathname,
    },
    member_id: member?.id,
  }

  analytics.track('custom_page_view', payload, {
    plugins: {
      all: false,
      'google-analytics': true,
      'google-tag-manager': true,
    },
  })

  analytics.page(payload, {
    plugins: {
      all: false,
      tealium: true,
    },
  })
}
