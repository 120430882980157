import { logger } from './logger'

/**
 * Gets the "signal" property for fetch requests.
 *
 * Example:
 * const response = await fetch('/api/thing', { signal: getAbortSignal(1000) })
 *
 * Why?
 * - AbortSignal.timeout only became cross-brower compatible in 2022. E.g., Safari >= 16.
 * - AbortController.abort only became cross-brower compatible in 2021. E.g., Safari >= 15.
 * - Fallback to undefined, which means the fetch request will run as long as it needs to.
 */
export function getAbortSignal(timeout: number) {
  if (AbortSignal && AbortSignal.timeout) {
    return AbortSignal.timeout(timeout)
  }

  if (AbortController) {
    const controller = new AbortController()
    const signal = controller.signal
    setTimeout(() => controller.abort(), timeout)
    return signal
  }

  // Browser does not support aborting fetch, { signal: undefined }
  return undefined
}

/**
 * Helper function for logging fetch exceptions when using { signal: getAbortSignal(500) }.
 */
export function handleFetchWithAbortException(cause: Error, message: string) {
  const errorName = cause.name

  if (errorName === 'TimeoutError' || errorName === 'AbortError') {
    /**
     * In some older browsers, they report AbortError as TimeoutError.
     * In browsers that correctly report AbortError, that refers to the page being closed.
     *
     * Reporting as an "error" instead of "info" is deliberate. There may be situations where we
     * need visibility into how often a request is not delivered due to timeout. Recall that we
     * do not send "info" logs to BugSnag.
     */
    logger('error', new Error(`${message} (Exceeded timeout or Aborted by user)`, { cause }))
  } else if (errorName === 'TypeError') {
    // Shouldn't happen if using { signal: getAbortSignal() }, but just in case
    logger('fatal', new Error(`${message} (Abort not supported)`, { cause }))
  } else {
    logger('error', new Error(`${message} (Unknown error)`, { cause }))
  }
}
