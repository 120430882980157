import React from 'react'
import NextImage from 'next/image'
import { Image, ImageProps } from '@classy/campaign-page-blocks'

interface ImageControllerProps extends ImageProps {}

export const ImageController = ({ ...rest }: ImageControllerProps) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <Image {...rest} NextImage={NextImage as unknown as typeof React.Component} />
)
