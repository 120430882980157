import React from 'react'
import { Countries } from 'country-to-currency'
import { getAbortSignal, handleFetchWithAbortException } from 'utils/abortSignal'

export interface ClientHeadersData {
  cfConnectingIp: string
  geoCountry: Countries
}

export const useClientHeadersData = () => {
  const [clientHeadersData, setClientHeadersData] = React.useState<ClientHeadersData>({
    cfConnectingIp: '',
    geoCountry: 'US',
  })

  const fetchClientHeadersData = React.useCallback(async () => {
    try {
      const response = await fetch('/api/clientHeadersData', {
        // Abort the fetch if it takes longer than 1 second
        signal: getAbortSignal(1000),
      })

      const json = await response.json()

      setClientHeadersData(json)
    } catch (e) {
      handleFetchWithAbortException(e as Error, 'Unable to read request headers data')
    }
  }, [])

  React.useEffect(() => {
    fetchClientHeadersData()
  }, [fetchClientHeadersData])

  return clientHeadersData
}
